@font-face {
  font-family: ultra;
  src: url("Audiowide-Regular.3e1b313c.ttf");
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

html {
  background-color: #000;
  font-family: Courier New, Courier, monospace;
}

button {
  font-family: Courier New, Courier, monospace;
}

a {
  color: #8a2be2;
  text-decoration: none;
}

._1o7wEW_paragraph {
  -webkit-backdrop-filter: blur(.5px);
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (max-width: 425px) {
  ._1o7wEW_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

._1o7wEW_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

._1o7wEW_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

._1o7wEW_info ul li {
  margin: 0 0 0 15px;
}

._1o7wEW_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

._1o7wEW_info:last-child {
  margin: 0;
}

@media (max-width: 425px) {
  ._1o7wEW_info {
    max-width: 320px;
    font-size: 15px;
  }
}

._1o7wEW_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

._1o7wEW_timeline {
  margin: 0 12px 60px;
}

._1o7wEW_follow {
  margin: 30px;
}

._1o7wEW_socialLinks {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

._1o7wEW_layout {
  flex-direction: column;
  align-items: center;
  margin: 0;
  display: flex;
  overflow: hidden;
}

._1o7wEW_content {
  flex-direction: column;
  flex: 1 0 auto;
  align-items: center;
  margin: 0;
  display: flex;
}

.Jcnh_G_paragraph {
  -webkit-backdrop-filter: blur(.5px);
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (max-width: 425px) {
  .Jcnh_G_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

.Jcnh_G_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

.Jcnh_G_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

.Jcnh_G_info ul li {
  margin: 0 0 0 15px;
}

.Jcnh_G_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

.Jcnh_G_info:last-child {
  margin: 0;
}

@media (max-width: 425px) {
  .Jcnh_G_info {
    max-width: 320px;
    font-size: 15px;
  }
}

.Jcnh_G_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

.Jcnh_G_timeline {
  margin: 0 12px 60px;
}

.Jcnh_G_follow {
  margin: 30px;
}

.Jcnh_G_socialLinks {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.Jcnh_G_header {
  color: #ff9046;
  text-align: center;
  margin: 80px 12px 0;
  font-family: ultra, Arial, Helvetica, sans-serif;
  font-size: 48px;
}

@media (max-width: 425px) {
  .Jcnh_G_header {
    font-size: 36px;
  }
}

._8jlUpW_paragraph {
  -webkit-backdrop-filter: blur(.5px);
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (max-width: 425px) {
  ._8jlUpW_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

._8jlUpW_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

._8jlUpW_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

._8jlUpW_info ul li {
  margin: 0 0 0 15px;
}

._8jlUpW_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

._8jlUpW_info:last-child {
  margin: 0;
}

@media (max-width: 425px) {
  ._8jlUpW_info {
    max-width: 320px;
    font-size: 15px;
  }
}

._8jlUpW_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

._8jlUpW_timeline {
  margin: 0 12px 60px;
}

._8jlUpW_follow {
  margin: 30px;
}

._8jlUpW_socialLinks {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

._8jlUpW_nav {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin: 30px 30px 0;
  display: flex;
}

._8jlUpW_mobileContainer {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  top: 60px;
  right: 0;
  overflow: hidden;
}

._8jlUpW_mobileContainer ._8jlUpW_mobileNav {
  text-align: center;
  pointer-events: all;
  z-index: 2;
  background-color: #3c3c3c;
  border-radius: 12px;
  flex-direction: column;
  max-width: 200px;
  transition: right .2s ease-in-out;
  display: flex;
  position: relative;
  right: -200px;
  box-shadow: 0 0 10px #00000080;
}

._8jlUpW_link {
  color: #ff9046;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ff9046;
  border-radius: 8px;
  width: 180px;
  margin: 12px;
  padding: 6px 12px;
  font-size: 22px;
  font-weight: bold;
  text-decoration: none;
  transition: all .2s ease-in-out;
}

._8jlUpW_link:hover {
  color: #000;
  background-color: #ff9046;
  scale: 1.08;
}

._8jlUpW_icon {
  color: #ff9046;
  font-size: 18pt;
  transition: all .2s ease-in-out;
  position: absolute;
  top: 21px;
  right: 21px;
}

._8jlUpW_icon:hover {
  scale: 1.2;
}

.-ZjTbW_paragraph {
  -webkit-backdrop-filter: blur(.5px);
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (max-width: 425px) {
  .-ZjTbW_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

.-ZjTbW_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

.-ZjTbW_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

.-ZjTbW_info ul li {
  margin: 0 0 0 15px;
}

.-ZjTbW_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

.-ZjTbW_info:last-child {
  margin: 0;
}

@media (max-width: 425px) {
  .-ZjTbW_info {
    max-width: 320px;
    font-size: 15px;
  }
}

.-ZjTbW_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

.-ZjTbW_timeline {
  margin: 0 12px 60px;
}

.-ZjTbW_follow {
  margin: 30px;
}

.-ZjTbW_socialLinks {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.-ZjTbW_footer {
  color: #ff9046;
  text-align: center;
  flex-shrink: 0;
  margin: 0 0 30px;
  font-size: 20px;
}

@media (max-width: 425px) {
  .-ZjTbW_footer {
    font-size: 15px;
  }
}

.A7AFIW_paragraph {
  -webkit-backdrop-filter: blur(.5px);
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (max-width: 425px) {
  .A7AFIW_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

.A7AFIW_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

.A7AFIW_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

.A7AFIW_info ul li {
  margin: 0 0 0 15px;
}

.A7AFIW_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

.A7AFIW_info:last-child {
  margin: 0;
}

@media (max-width: 425px) {
  .A7AFIW_info {
    max-width: 320px;
    font-size: 15px;
  }
}

.A7AFIW_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

.A7AFIW_timeline {
  margin: 0 12px 60px;
}

.A7AFIW_follow {
  margin: 30px;
}

.A7AFIW_socialLinks {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.A7AFIW_container {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  position: absolute;
  top: 21px;
  left: 21px;
}

.A7AFIW_support {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.A7AFIW_tipButton {
  color: #ff9046;
  background-color: #fff0;
  border: 2px solid #ff9046;
  border-radius: 6px;
  margin: 0;
  padding: 6px 12px;
  font-size: 9pt;
  font-weight: bold;
}

.A7AFIW_tipButton:hover {
  color: #000;
  background-color: #ff9046;
  transition: all .2s ease-in-out;
  scale: 1.08;
}

.A7AFIW_myDogeImage {
  object-fit: cover;
  border-radius: 6px;
  width: 30px;
  height: 30px;
}

.A7AFIW_text {
  color: #ff9046;
  margin: 0 0 0 12px;
  font-size: 15pt;
}

.IqVXpG_paragraph {
  -webkit-backdrop-filter: blur(.5px);
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (max-width: 425px) {
  .IqVXpG_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

.IqVXpG_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

.IqVXpG_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

.IqVXpG_info ul li {
  margin: 0 0 0 15px;
}

.IqVXpG_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

.IqVXpG_info:last-child {
  margin: 0;
}

@media (max-width: 425px) {
  .IqVXpG_info {
    max-width: 320px;
    font-size: 15px;
  }
}

.IqVXpG_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

.IqVXpG_timeline {
  margin: 0 12px 60px;
}

.IqVXpG_follow {
  margin: 30px;
}

.IqVXpG_socialLinks {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.IqVXpG_playlist {
  margin: 60px 12px 0;
}

.IqVXpG_playlist iframe {
  -webkit-user-select: none;
  user-select: none;
  border: none;
  width: 100vw;
  max-width: 1200px;
  height: 56.25vw;
  max-height: 720px;
  margin: 0;
}

@media (max-width: 772px) {
  .IqVXpG_playlist iframe {
    width: 100vw;
  }
}

.IqVXpG_video {
  margin: 18px 0 12px;
}

.kJMGNW_paragraph {
  -webkit-backdrop-filter: blur(.5px);
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (max-width: 425px) {
  .kJMGNW_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

.kJMGNW_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

.kJMGNW_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

.kJMGNW_info ul li {
  margin: 0 0 0 15px;
}

.kJMGNW_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

.kJMGNW_info:last-child {
  margin: 0;
}

@media (max-width: 425px) {
  .kJMGNW_info {
    max-width: 320px;
    font-size: 15px;
  }
}

.kJMGNW_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

.kJMGNW_timeline {
  margin: 0 12px 60px;
}

.kJMGNW_follow {
  margin: 30px;
}

.kJMGNW_socialLinks {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.p9gHYq_paragraph {
  -webkit-backdrop-filter: blur(.5px);
  backdrop-filter: blur(.5px);
  color: #ff9046;
  background-color: #ffffff1f;
  border-radius: 30px;
  max-width: 720px;
  margin: 60px 12px;
  padding: 15px 30px;
  font-size: 28px;
  line-height: 1.8;
}

@media (max-width: 425px) {
  .p9gHYq_paragraph {
    border-radius: 15px;
    width: 300px;
    margin: 30px;
    padding: 12px 24px;
    font-size: 18px;
  }
}

.p9gHYq_info {
  text-align: center;
  color: #ff9046;
  flex-direction: column;
  align-items: center;
  margin: 0 0 60px;
  font-size: 20px;
  display: flex;
}

.p9gHYq_info ul {
  text-align: left;
  background-color: #ffffff1f;
  border: 2px solid #ff9046;
  border-radius: 10px;
  min-width: 320px;
  max-width: 425px;
  margin: 0 0 60px;
  padding: 10px;
}

.p9gHYq_info ul li {
  margin: 0 0 0 15px;
}

.p9gHYq_info ul p {
  text-align: center;
  margin: 0 0 15px;
  font-size: 18px;
}

.p9gHYq_info:last-child {
  margin: 0;
}

@media (max-width: 425px) {
  .p9gHYq_info {
    max-width: 320px;
    font-size: 15px;
  }
}

.p9gHYq_screenshot {
  image-rendering: high-quality;
  border: 1px solid #ff9046;
  border-radius: 36px;
  width: 300px;
  height: 540px;
  margin: 0 30px 60px;
}

.p9gHYq_timeline {
  margin: 0 12px 60px;
}

.p9gHYq_follow {
  margin: 30px;
}

.p9gHYq_socialLinks {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  display: flex;
}

.p9gHYq_icon {
  margin: 12px 24px;
  font-size: 30pt;
  transition: all .2s ease-in-out;
}

.p9gHYq_icon:hover {
  scale: 1.2;
}

.e21GTW_game {
  transform-origin: 0 0;
  justify-content: center;
  margin: 60px 0;
  display: flex;
}

.e21GTW_game iframe {
  border: none;
}

.UN7yjq_images {
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 15px 30px;
  padding: 30px;
  display: flex;
}

@media (max-width: 650px) {
  .UN7yjq_images {
    flex-direction: column;
    align-items: center;
  }

  .UN7yjq_images :last-child {
    margin: 0 30px;
  }
}

/*# sourceMappingURL=index.d22cd760.css.map */
